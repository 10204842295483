import './App.css';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { getParsedNftAccountsByOwner, isValidSolanaAddress, createConnectionConfig, } from "@nfteyez/sol-rayz";
import { Col, Row, Button, Form, Card } from "react-bootstrap";
import AlertDismissible from './alert/alertDismissible';
import * as Token from "@solana/spl-token"
import { Keypair, SystemProgram, Transaction, PublicKey} from "@solana/web3.js";
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { SendOneLamportToRandomAddress } from './SOLTransfer';
import { TransferRice } from './TransferRICE';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

import { getDatabase, ref, child, get } from "firebase/database";


function App(props) {

  // FireBase 
  const firebaseConfig = {
    apiKey: "AIzaSyAYS9rRAMn9_ko8kesK7tNHZAg19k7Q5to",
    authDomain: "solana-robo.firebaseapp.com",
    databaseURL: "https://solana-robo-default-rtdb.firebaseio.com",
    projectId: "solana-robo",
    storageBucket: "solana-robo.appspot.com",
    messagingSenderId: "667791838862",
    appId: "1:667791838862:web:d9ab214b16c7b0f1c9c3df",
    measurementId: "G-80R38FCFJE"
  };
  
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);

  const db = getDatabase();


  // Get a list of cities from your database
  async function getData(db) {
    const datesCollection = collection(db, 'solana-robo-default-rtdb');
    const datesSnapshot = await getDocs(datesCollection);
    const cityList = datesSnapshot.docs.map(doc => doc.data());
    return cityList;
  }

  const getEggDateFromToken = async (e,token) => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `egg/byToken/${token}`)).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }


  const { publicKey, sendTransaction } = useWallet();
  const { connection } = props;

  // Recieving Wallet
  const toWallet = 'ERcE2jS7dfpDXk2dVAtaEG33Tz6iTt2fUxe2NEaFhgLT';

  // Mint token to recieve
  const mintToken = '5yw793FZPCaPcuUN4F61VJh2ehsFX87zvHbCA4oRebfn';

  // input ref
  const inputRef = useRef();

  // state change
  useEffect(() => {
    setNfts([]);
    setShow(false);
     if (publicKey) {
       inputRef.current.value = publicKey;
     }
  }, [publicKey, connection]);
  const [nfts, setNfts] = useState([]);

  //alert props
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("");

  //loading props
  const [loading, setLoading] = useState(false);

  //selected nft props
  const [roboCockInvidia, setRoboCockInvidia] = useState("RC_----");
  const [roboCockVirtus, setRoboCockVirtus] = useState("RC_----");

  const [isHolder, setIsHolder] = useState(false);

  
  const getNfts = async (e) => {
    e.preventDefault();

    setShow(false);

    let address = inputRef.current.value;

    if (address.length === 0) {
      address = publicKey;
    }

    if (!isValidSolanaAddress(address)) {
      setTitle("Wallet not connected");
      setMessage("Please (re)connect your wallet and try again.");
      setVariant("danger");
      setLoading(false);
      setShow(true);
      return;
    }

    

    const connect = createConnectionConfig(connection);

    setLoading(true);

    const nftArray = await fetch('https://api-mainnet.magiceden.dev/v2/wallets/'+publicKey+'/tokens?offset=0&limit=100')
      .then(response => response.json())
      .then(data => {
        return data;
      });

      console.log(nftArray)
    if (nftArray.length === 0) {
      setTitle("No NFTs found in " + props.title);
      setMessage("No NFTs found for address: " + address);
      setLoading(false);
      setVariant("danger");
      setShow(true);
      return;
    }

    const metadatas = await fetchMetadata(nftArray);
        console.log("Got nfts metadata");
    setLoading(false);
    return setNfts(metadatas);
  };

  const fetchMetadata = async (nftArray) => {
    let metadatas = [];
    for (const nft of nftArray) {
        if (nft.updateAuthority === '7FvS9S7frNaWwZaUoRPzJBBcX13Z6HP5h3AqoVPAn1dk' && nft.externalUrl === 'https://www.robocock.io/') {
          metadatas.push(nft);
          setIsHolder(true);
        }
        console.log(metadatas);
    }
    return metadatas;
  };

  const onClick = (event,name,faction) => {
    if (faction === 'Invidia') {
      setRoboCockInvidia(name);
      console.log('Set '+name)
    } else if (faction === 'Virtus') {
      setRoboCockVirtus(name);
      console.log('Set '+name)
    }
  }

  const handleSelectedCheck = (e,name) => {
    if (name === roboCockInvidia || name === roboCockVirtus) {
      return ' | Selected'
    }
  }

  const sumbitHandler = (e) => {
    setTitle("Transation Sent");
    setMessage("This process may take a few minutes.");
    setLoading(true);
    setVariant("success");
    setShow(true);

    return;
  }

  return (
    
    <div className="main">
      
      <Row className="inputForm">
        <Col xs="0" md="0" lg="0">
          <Form.Control
            type="text"
            ref={inputRef}
            placeholder="Wallet address"
            disabled={true}
            className="text-input"
          />
        </Col>
        <Col className="d-grid">
        {
          isHolder===true
          ?
          <></>
          :
          <Button
            variant="info"
            type="submit"
            onClick={getNfts}
          >
            {" "}
            Verify Wallet and Fetch RoboCocks{" "}
          </Button>
        }
           
        </Col> 
      </Row>
      <Row>
        {loading && <p className='main-text'>This process may take a minute.</p>}
      </Row>
      <Row>
        <Col xs="12" md="12" lg="5"></Col>
        
        { isHolder &&
          <h2 className="combine">Invidia | {roboCockInvidia} + {roboCockVirtus} | Virtus</h2>
        }
        {
          roboCockInvidia==='RC_----' || roboCockVirtus==='RC_----' || loading===true
          ?
          <Button variant="dark" disabled={true}>Breed</Button>
          :
          <Button variant="light" onClick={e => sumbitHandler(e)} disabled={false}>Breed</Button>
        }
        
  
      
      </Row>
      {loading && (
        <div className="loading">
          <img
            src="loading.gif"
            alt="loading"
          />
        </div>
      )}
      <Row>
        {!loading && nfts.map((metadata, index) => (
          <Col xs="12" md="12" lg="3">
            <Card
              className="imageGrid"
              lg="3"
              key={index}
              style={{ width: "100%" }}
              onClick={e => onClick(e,metadata?.name,metadata?.attributes[9]?.value)}
            >
              <Card.Img
                variant="top"
                src={metadata?.image}
                alt={metadata?.name}
              />
              <Card.Body>
                <Card.Title>{metadata?.name} | {metadata?.attributes[9]?.value}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <TransferRice />
      <Button onClick={e => getEggDateFromToken(e,'2bM1dzhoaym3gseGFxHosmLspKEYYJtXpYzXsA1LH4q')}>Get Token</Button>
      <p>&nbsp; &nbsp;</p>
      {show && (
        <AlertDismissible title={title} message={message} setShow={setShow} variant={variant} />
      )}
      

    </div>
  );
}

export default App;
