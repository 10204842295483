import React from "react";
import { Col, Row, Button, Form, Card } from "react-bootstrap";

const Footer = () => (
  <Row
    style={{
      position: "fixed",
      bottom: "0",
      padding: "10px",
      width: "100%",
      backgroundColor: "#272828",
    }}
  >
    <Col xs="50" md="12" lg="12" className="text-center">
      <div className="footer-text" style={{ color: "#fff"}}>
        EKAJA X IAMCOW | NIORE TEAM | ROBOCOCK NFT
      </div>
    </Col>
  </Row>
);

export default Footer;
