import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Keypair, PublicKey, SystemProgram, Transaction, clusterApiUrl, Connection, LAMPORTS_PER_SOL } from '@solana/web3.js';
import React, { useCallback } from 'react';
import { Button, FormGroup } from "react-bootstrap";
import * as Token from "@solana/spl-token";
import { createMint, getOrCreateAssociatedTokenAccount, mintTo, transfer } from "@solana/spl-token";

export const TransferRice = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();

    const onClick = useCallback(async () => {
console.log("started");

        if (!publicKey) throw new WalletNotConnectedError();

        const fromWallet = new PublicKey(publicKey);
        const toWallet = new PublicKey('ERcE2jS7dfpDXk2dVAtaEG33Tz6iTt2fUxe2NEaFhgLT');

        const mint = new PublicKey('5yw793FZPCaPcuUN4F61VJh2ehsFX87zvHbCA4oRebfn');

        const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
            connection,
            fromWallet,
            mint,
            fromWallet
        );

        const toTokenAccount = new PublicKey('xAvER6BAKccUfsrosf86ooLzdnUjZrJEvNtxaJQwXE3');

        const program = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');


        const transaction = new Transaction().add(
            Token.createTransferInstruction(
              fromTokenAccount.address,
              toTokenAccount,
              fromWallet,
              1*100
            )
          );


        console.log("transaction created!")
console.log(transaction)
console.log("attempt send")
        const signature = await sendTransaction(transaction, connection);
console.log("Send successful")
console.log(signature)

        await connection.confirmTransaction(signature, 'processed');
    }, [publicKey, sendTransaction, connection]);

    return (
        <Button variant="warning" onClick={onClick} disabled={!publicKey}>
            Send SPL
        </Button>
    );
};